import jwtDecode from "jwt-decode";

export function getAuthData(name = "authToken") {
    return window.localStorage.getItem(name);
  }

export function authenticateUser(token) {
    const jwt = token || getAuthData();
    if (!jwt) return [false];
    const decoded = jwtDecode(jwt);
    if (!decoded || !decoded.exp) return [false];
    const now = new Date();
    const nowInSec = Math.floor(now.getTime() * 0.001);
    return [decoded.exp > nowInSec, decoded];
   
  }